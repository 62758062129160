import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/Seo"
import styled from "styled-components"
import ReCAPTCHA from 'react-google-recaptcha';

const FormDescription = styled.div`
  width: 100%;
  margin: auto;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-top: 1rem;
`

const Input = styled.input`
  margin-bottom: 1.5rem;
  padding: 10px;
`

const Textarea = styled.textarea`
  margin-bottom: 1.5rem;
  padding: 10px;
`

const Button = styled.button`
  margin-bottom: 1.5rem;
  font-weight: bold;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 5px;
  width: 100px;
`

const Contact = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <FormDescription>
        <p>
          Please contact me below for more information about my availability and
          rates for musical performances or music therapy services.
        </p>
      </FormDescription>

      <Form
        method="POST"
        action="https://getform.io/f/aa4c89cb-99d7-42b7-9d29-6fdd57a49170"
        name="contact-form"
      >
        <Input
          type="text"
          name="name"
          placeholder="Enter your name"
          required
          minlegnth="3"
          maxlength="100"
        />
        <Input
          type="email"
          name="email"
          placeholder="Enter your email"
          maxlength="100"
          required
        />
        <Textarea
          id="question"
          name="question"
          rows="7"
          placeholder="Enter your inquiry"
          maxlength="350"
          required
        />
        <Button type="submit">Submit</Button>
        <ReCAPTCHA sitekey="6LenHVIbAAAAAD8Kgzs9dVJZ0TWYvW_KlVsP3YVI" data-sitekey="6LenHVIbAAAAAD8Kgzs9dVJZ0TWYvW_KlVsP3YVI"/>
      </Form>
    </Layout>
  )
}

export default Contact
